<template>
  <div>
    <el-card>
      <div class="flexRow">
        <div class="flexRow keybox">
          <div class="keyfont">关键字：</div>
          <el-input v-model="keywords" style="width:200px" placeholder="微信昵称、姓名、手机号"></el-input>
        </div>
        <el-button type="primary" class="keybox" style="margin-right:10px" @click="currentPage=1,gettablelist()">查询</el-button>
        <buttonPermissions :datas="'blacklistmanagement'">
          <el-button type="primary" class="keybox" @click="management({Id:0})">添加</el-button>
        </buttonPermissions>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="" label="客户">
            <template slot-scope="scope">
              <div class="flexRow" style="width:100%">
                <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaultheader" style="width:50px;height:50px;border-radius:5px" alt="">
                <div style="margin-left:5px;width:80%">
                  <div class="fonthidden">
                    <span>{{scope.row.WxNickname}}</span>
                    <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                  </div>
                  <div>{{scope.row.Phone}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ProhibitTypeValue" label="禁止行为"></el-table-column>
          <el-table-column prop="AddTime" label="添加时间"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <div>
                <buttonPermissions :datas="'blacklistmanagement'" style="margin-right:10px">
                  <el-button type="text" @click="management(scope.row)">行为管理</el-button>
                </buttonPermissions>
                <buttonPermissions :datas="'blacklistremove'" style="margin-right:10px">
                  <el-button type="text" @click="remove(scope.row)">移除黑名单</el-button>
                </buttonPermissions>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="managementShow" :title="rowmsg.Id?'禁止行为管理':'添加黑名单客户'" width="1000px" @close="close" custom-class="dialogpad">
      <div style="display:flex">
        <div class="management-label">客户：</div>
        <div style="margin-bottom:20px;flex:1" v-if="rowmsg.Id">
          <div class="flexRow" v-for="(v,i) in managementCustomer" :key="i">
            <img :src="v.WxHeadUrl?v.WxHeadUrl:defaultheader" style="border-radius:5px;width:80px;height:80px" alt="">
            <div style="flex:1;margin-left:5px">
              <div>{{v.WxNickname}}
                <span v-if="v.Name">{{v.Name}}</span>
              </div>
              <div>{{v.Phone}}</div>
            </div>
          </div>
        </div>
        <div style="flex:1" v-else>
          <div class="flexRow">
            <el-input v-model.trim="managementKeywords" placeholder="请输入手机号" style="width:200px;margin-right:10px"></el-input>
            <el-button type="primary" @click="search">查询</el-button>
          </div>
          <div class="grayfont" style="margin-top:5px">输入客户手机号查找客户；可查找多个客户批量加入黑名单</div>
          <div class="flexRow" v-loading="searchloading" style="flex-wrap:wrap;overflow:auto;max-height:320px;padding-top:20px">
            <div class="customerBox" v-for="(v,i) in managementCustomer" :key="i">
              <div class="imgbox">
                <img :src="v.WxHeadUrl?v.WxHeadUrl:defaultheader" style="border-radius:5px;width:100%" alt="">
                <div class="closeIcon" @click="clear(i)">
                  <i class="el-icon-error"></i>
                </div>
              </div>
              <div class="phone">{{v.Phone}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex">
        <div class="management-label">行为设置：</div>
        <div style="flex:1">
          <el-table :data="managementBehavior" style="width:100%">
            <el-table-column prop="" label="行为">
              <template slot-scope="scope">
                <div>
                  <div style="color:#606266">{{scope.row.title}}</div>
                  <div style="color:#909399">{{scope.row.content}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="禁止操作" width="100px">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.check"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="margin-top:10px;text-align:right">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" :loading="managementLoading" @click="confirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import buttonPermissions from '@/components/buttonPermissions';
import {
  memberBlackmemberBlackPageList,
  memberBlackaddMemberBlack,
  memberBlackmemberBlackManager,
  memberBlackmemberBlackRemove
} from "@/api/sv3.0.0"
import {
  memberinfobyMemberId
} from "@/api/TurnTomySelf"
export default {
  components:{
    buttonPermissions
  },
  data () {
    return {
      defaultheader:config.DEFAULT_HEADER,
      keywords:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pageSize:20,
      total:null,

      rowmsg:{},
      managementShow:false,
      managementLoading:false,
      managementKeywords:'',
      searchloading:false,
      managementCustomer:[],
      managementBehavior:[
        {
          Id:1,
          title:'积分兑换商品',
          content:'禁止该行为后，客户进入积分兑换详情页，不可兑换商品',
          check:false,
        },
        {
          Id:2,
          title:'积分兑换优惠券',
          content:'禁止该行为后，客户进入积分兑换详情页，不可兑换优惠券',
          check:false,
        },
      ]
      //KeywordType  1
      //KeyWord
    }
  },
  created () {
    this.gettablelist()
  },
  methods: {
    confirm(){
      if(this.rowmsg.Id){
        this.confirmban()
      }else{
        this.confirmpush()
      }
    },
    async confirmban(){
      if(!this.managementBehavior.some(v=>v.check)){
        this.$message.error('请勾选禁止行为')
        return
      }
      try{
        this.managementLoading = true
        let res = await memberBlackmemberBlackManager({
          Id:this.rowmsg.Id,
          ProhibitType:this.managementBehavior.filter(v=>v.check).map(v=>v.Id)
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.close()
          this.gettablelist()
        }
      }finally{
        this.managementLoading = false
      }
    },
    async confirmpush(){
      if(!this.managementCustomer||!this.managementCustomer.length){
        this.$message.error('请输入手机号，查询并添加需要进入黑名单的客户')
        return
      }else if(!this.managementBehavior.some(v=>v.check)){
        this.$message.error('请勾选禁止行为')
        return
      }
      try{
        this.managementLoading = true
        for(let item of this.managementCustomer){
          await memberBlackaddMemberBlack({
            MemberId:item.Id,
            ProhibitType:this.managementBehavior.filter(v=>v.check).map(v=>v.Id)
          })
        }
      }finally{
        this.managementLoading = false
        this.$message.success('操作成功')
        this.close()
        this.gettablelist()
      }
    },
    close(){
      this.managementKeywords = ''
      this.managementCustomer = []
      this.managementBehavior = this.managementBehavior.map(v=>{
        v.check = false
        return v
      })
      this.rowmsg = {}
      this.managementShow = false
    },
    async search(){
      if(!this.managementKeywords){
        this.$message.error('请输入手机号')
        return
      }else if(this.managementKeywords.length!=11){
        this.$message.error('请输入完整的11位手机号')
        return
      }else if(this.managementCustomer.some(v=>v.Phone==this.managementKeywords)){
        this.$message.error('该手机号已添加，请勿重复查询')
        return 
      }

      try{
        this.searchloading = true
        let res = await memberinfobyMemberId({
          Phone:this.managementKeywords,
        })
        if(res.IsSuccess){
          if(res.Result.Id){
            this.managementCustomer.unshift(res.Result)
          }else{
            this.$message.error('手机号错误')
          }
        }
      }finally{
        this.searchloading = false
      }

    },
    clear(e){
      this.managementCustomer.splice(e,1)
    },
    management(row){
      this.rowmsg = row
      if(row.Id){
        this.managementCustomer = [row]
        this.managementBehavior = this.managementBehavior.map(v=>{
          v.check = row.ProhibitType.some(x=>x==v.Id)
          return v
        })
      }
      this.managementShow = true
    },
    async remove(row){
      try{
        this.tableloading = true

        let res = await memberBlackmemberBlackRemove({
          Id:await row.Id
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pageSize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Keywords: this.keywords,
          Skip:(this.currentPage-1)*this.pageSize,
          Take: this.pageSize,
        }
        let res = await memberBlackmemberBlackPageList(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }, 
  }
}
</script>

<style lang="less">
.dialogpad .el-dialog__header{
  padding: 20px 20px 0px 20px
}
</style>
<style lang="less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.keybox{
  margin:0px 10px 10px 0px
}
.keyfont{
  font-size: 14px;
  color: #606266;
  font-weight: bold;
  white-space: nowrap;
}
.fonthidden{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grayfont{
  font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.management-label{
  flex-shrink:0;
  line-height:36px;
  width:70px;
  text-align:right;
  font-weight:bold;
  margin-right:10px
}
.customerBox{
  // border:1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px 20px 0px;
  .imgbox{
    position: relative;
    top: 0px;
    left: 0px;
    img{
      width: 80px;height: 80px;
      border-radius: 2px;
    }
    .closeIcon{
      position: absolute;
      left: 70px;
      top: -10px;
      // border: 1px solid red;
      cursor: pointer;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .phone{
    font-size: 14px;
    color: #606266;
    line-height: 1.5;
    margin: 5px 0px;
  }
}
</style>